import { defineMessages } from 'react-intl'

export const commonMessages = defineMessages({
  action: { defaultMessage: 'Action', id: 'QlsDcr' },
  SKU: { defaultMessage: 'SKU', id: 'k4brJy' },
  seriesId: { defaultMessage: 'Series ID', id: '1uQBuI' },
  subseriesId: { defaultMessage: 'Subseries ID', id: 'kSRXU5' },
  seriesName: { defaultMessage: 'Series', id: 'ORsvNl' },
  store: { defaultMessage: 'Store', id: 'RTOlSm' },
  stores: { defaultMessage: 'Stores', id: '68x5L8' },
  category: { defaultMessage: 'Category', id: 'ccXLVi' },
  subcategory: { defaultMessage: 'Sub-Category', id: '8Ua7wn' },
  storeCount: { defaultMessage: 'Store Count', id: 'AzAroF' },
  pastSales90Days: { defaultMessage: 'Past Sales (90-Day)', id: 'SxLutB' },
  predictedSales90Days: { defaultMessage: 'Predicted Sales (90-Day)', id: 'Q4Xtm1' },
  productName: { defaultMessage: 'Product Name', id: 'ZIc5lM' },
  name: { defaultMessage: 'Name', id: 'HAlOn1' },
  add: { defaultMessage: 'Add', id: '2/2yg+' },
  remove: { defaultMessage: 'Remove', id: 'G/yZLu' },
  none: { defaultMessage: 'None', id: '450Fty' },
  recommendations: { defaultMessage: 'Recommendations', id: 'EnTkxu' },
  predictedImpact: { defaultMessage: 'Predicted Impact', id: 'GFLDlF' },
  NA: { defaultMessage: 'N/A', id: 'PW+sL4' },
  recommendedSeries: { defaultMessage: 'Recommended Series', id: 'a/n1YD' },
  existingStores: { defaultMessage: 'Existing Stores', id: '1cFgQQ' },
  addStoreCount: { defaultMessage: 'Add Store Count', id: 'C+9TXL' },
  pastSales90DaysAllStores: { defaultMessage: 'Past Sales - All Stores (90-Day)', id: 'q2oQvG' },
  cancel: { defaultMessage: 'Cancel', id: '47FYwb' },
  submit: { defaultMessage: 'Submit', id: 'wSZR47' },
  recommendation: { defaultMessage: 'Recommendation', id: 'ODn2y0' },
  id: { defaultMessage: 'ID', id: 'qlcuNQ' },
  existing: { defaultMessage: 'Existing', id: 'LHF3Za' },
  pastSalesAll: { defaultMessage: 'Past Sales (All)', id: 'MtHZgr' },
  pastSalesStore: { defaultMessage: 'Past Sales (Recommended Stores)', id: 'cxNpaK' },
  predictedSalesStore: { defaultMessage: 'Predicted Sales (Recommended Stores)', id: 'kA/GgV' },
  predictedSalesSeries: { defaultMessage: 'Predicted Sales (Series)', id: '4e7z4o' },
  swapStatus: { defaultMessage: 'Swap Status', id: 'pbfJwM' },
  executionDate: { defaultMessage: 'Execution Date', id: 'tCsDyb' },
  needsAttention: { defaultMessage: 'Needs Attention', id: 'K+cFF2' },
  completed: { defaultMessage: 'Completed', id: '95stPq' },
  inProgress: { defaultMessage: 'In Progress', id: 'B487HA' },
  status: { defaultMessage: 'Status', id: 'tzMNF3' },
  seriesInactive: { defaultMessage: 'Inactive Series', id: 'GyYTUw' },
  nowOnFloor: { defaultMessage: 'Now on floor', id: 'LaQ4kB' },
  noLongerOnFloor: { defaultMessage: 'No longer on floor', id: 'x7fNIf' },
  back: { defaultMessage: 'Back', id: 'cyR7Kh' },
  confirm: { defaultMessage: 'Confirm', id: 'N2IrpM' },
  rank: { defaultMessage: 'Rank', id: 'VP5+CR' },
  avgMonthlyPastSales: { defaultMessage: 'Past Sales', id: 'i6dcrc' },
  avgMonthlyPredictedSales: { defaultMessage: 'Predicted Sales', id: 'erj0Av' },
  currentRank: { defaultMessage: 'Current Rank', id: 'po0br1' },
  dataAdminCenter: { defaultMessage: 'Data Center', id: '3i7Hau' },
  plan: { defaultMessage: 'Plan', id: 'fz0z4c' },
  somethingWentWrong: { defaultMessage: 'Something went wrong', id: 'JqiqNj' },
  predictedSales: { defaultMessage: 'Predicted Sales', id: 'erj0Av' },
  inactive: { defaultMessage: 'Inactive Series', id: 'GyYTUw' },
  executionDueDate: { defaultMessage: 'Execution Due Date', id: 'By26hC' },
  notes: { defaultMessage: 'Notes', id: '7+Domh' }
})
